import { statAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(statAnatomy.keys)

const baseStyle = definePartsStyle({
	container: {
		p: 5,
		_light: {
			backgroundColor: '#fff',
			boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)',
			rounded: 'lg',
		},
		_dark: {
			backgroundColor: 'midGray',
			boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)',
			rounded: 'lg',
		},
	},
})

export const statTheme = defineMultiStyleConfig({ baseStyle })
