import { defineStyle, defineStyleConfig, cssVar } from '@chakra-ui/react'

const $startColor = cssVar('skeleton-start-color')
const $endColor = cssVar('skeleton-end-color')

const gray = defineStyle({
	_light: {
		[$startColor.variable]: '#E5E4E2',
		[$endColor.variable]: '#71797E',
	},
	_dark: {
		[$startColor.variable]: '#C0C0C0',
		[$endColor.variable]: '#71797E',
	},
})
export const skeletonTheme = defineStyleConfig({
	variants: { gray },
	defaultProps: {
		variant: gray,
	},
})
