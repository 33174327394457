import { SimpleGrid, Text } from '@chakra-ui/react'

export const SuspenseError = ({ error }) => {
	const errorStatus = error?.cause?.errorResponse?.status ?? error.message
	const errorMessage = error?.cause?.errorResponse?.message ?? error.message

	return (
		<SimpleGrid placeItems='center'>
			<Text fontSize='18px' mt={3} mb={2}>
				{errorMessage}
			</Text>
			<Text color='green' mb={6} fontSize='xxx-large' fontWeight='500'>
				{errorStatus}
			</Text>
		</SimpleGrid>
	)
}
