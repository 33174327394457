import { SearchIcon } from '@chakra-ui/icons'
import { CardHeader, Input, InputGroup, InputLeftElement, Spinner } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'

const fetching = 'fetching'

export const MainTableHeader = ({ placeholder = 'Search', render = null, fetchStatus = 'idle' }) => {
	const [searchParams, setSearchParams] = useSearchParams({
		q: '',
	})

	const query = searchParams.get('q') ?? ''

	const setParams = (e) => {
		const { value } = e.target
		setSearchParams((prev) => {
			prev.set('q', value)
			return prev
		})
	}

	return (
		<CardHeader display='flex' justifyContent='space-between' gap={3}>
			<InputGroup w='80'>
				<InputLeftElement>{fetchStatus === fetching ? <Spinner size='sm' /> : <SearchIcon />}</InputLeftElement>
				<Input
					_focusVisible={{ border: '1px solid #63b3ed' }}
					name='q'
					borderRadius='full'
					type='search'
					placeholder={placeholder}
					value={query}
					onChange={setParams}
				/>
			</InputGroup>
			{!!render && render()}
		</CardHeader>
	)
}
