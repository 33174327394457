import './sidebar.css'
import { useCallback, useMemo, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'oidc-react'
import { IconLogoutCircleRLine, IconProfile, SolaborateLogoCollapsed, SolaborateLogoExpanded } from 'icons/global'
import { MoonIcon, Search2Icon, SunIcon } from '@chakra-ui/icons'
import {
	Avatar,
	Badge,
	Box,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Text,
	useColorMode,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react'
import { useIsomorphicLayoutEffect } from 'hooks'
import { GlobalSearchModal } from 'components'
import { getUserRole } from 'helpers'
import { tabs } from 'layout/routeTabs'

const DASHBOARD = 0

const Sidebar = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { colorMode, toggleColorMode } = useColorMode()
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const {
		userManager,
		userData: { profile },
	} = useAuth()

	const handleSignout = useCallback(() => {
		userManager.removeUser()
		userManager.signoutRedirect()
		localStorage.clear()
	}, [])

	const initialTab = tabs.findIndex((tab) => tab.children.some((item) => pathname.includes(item.path)))

	const [activeTab, setActiveTab] = useState(initialTab >= 0 ? initialTab : DASHBOARD)

	const appBarBackground = useColorModeValue('#f7f7f7', '#0a0a0a')
	const sidebarBackground = useColorModeValue('#ffffff', '#1a1a1a')

	const handleTabClick = (id, route) => {
		setActiveTab(id)
		if (route && 'path' in route) {
			return navigate(route.path)
		}
		if (route && route.children && route.children[0]) {
			return navigate(route.children[0].path)
		}
	}

	useIsomorphicLayoutEffect(() => {
		setActiveTab(initialTab >= 0 ? initialTab : DASHBOARD)
	}, [initialTab])

	const hasChildren = useMemo(() => tabs[activeTab] && tabs[activeTab].children.length > 0, [activeTab])

	const isDarkMode = colorMode === 'dark'

	const role = getUserRole(profile.role)

	return (
		<div className='sidebar-wrapper'>
			<Box className='app-bar' bg={appBarBackground}>
				<Box mt='6' mb='4' mx='5'>
					<SolaborateLogoCollapsed />
				</Box>
				<div className='app-bar-list'>
					{tabs.map((route, index) => {
						return (
							<div
								className={activeTab === index ? 'app-bar-item-active' : 'app-bar-item'}
								key={route.label}
								onClick={() => handleTabClick(index, route)}>
								<span className='material-symbols-outlined app-bar-icon'>{route.icon}</span>
								<span className='app-bar-text'>{route.label}</span>
							</div>
						)
					})}
				</div>
				<div className='app-bar-footer'>
					<Flex mb='6' flexDirection='column'>
						<IconButton icon={<Search2Icon />} onClick={onOpen} bg={0} mx='auto' color='#247ef9' rounded='full' />
						<Text fontSize='12px' fontWeight='500' lineHeight='16px' textAlign='center'>
							Search
						</Text>
					</Flex>
					<Menu>
						<MenuButton>
							<Avatar name={`${profile.given_name} ${profile.family_name}`} />
						</MenuButton>
						<MenuList>
							<Flex justifyContent='space-around' alignItems='center'>
								<Text fontSize='lg'>
									{profile.given_name} {profile.family_name}
								</Text>
								<Badge colorScheme='green' px='1' rounded='sm' fontSize='sm'>
									{role}
								</Badge>
							</Flex>
							<MenuDivider />
							<MenuItem icon={<IconProfile fontSize='20' />}>My Account</MenuItem>
							<MenuItem icon={isDarkMode ? <MoonIcon fontSize='20' /> : <SunIcon fontSize='20' />} onClick={toggleColorMode}>
								{isDarkMode ? 'Dark Mode' : 'Light Mode'}
							</MenuItem>
							<MenuItem icon={<IconLogoutCircleRLine fontSize='20' />} onClick={handleSignout}>
								Logout
							</MenuItem>
						</MenuList>
					</Menu>
				</div>
			</Box>
			{hasChildren && (
				<Box className={isDarkMode ? 'sidebar-content' : 'sidebar-content-light'} bg={sidebarBackground}>
					<div className='sidebar-content-header'>
						<Box my={9} mr='8'>
							<SolaborateLogoExpanded />
						</Box>
					</div>
					<Box className='nav-list'>
						{tabs[activeTab].children.map((tab) => (
							<NavLink
								key={tab.path}
								to={tab.path}
								className={({ isActive }) => (isActive ? 'list-item-active' : 'list-item')}
								style={({ isActive }) => ({
									backgroundColor: !isDarkMode && isActive ? '#f6f6f6' : null,
									color: !isDarkMode && isActive ? 'black' : null,
									border: !isDarkMode && isActive ? '1px solid #98c9fb' : '1px solid transparent',
								})}>
								<span className='list-item-text'>{tab.text}</span>
							</NavLink>
						))}
					</Box>
				</Box>
			)}
			{isOpen && <GlobalSearchModal isOpen={isOpen} onClose={onClose} />}
		</div>
	)
}

export default Sidebar
