import { Modal, ModalBody, ModalContent, ModalOverlay, useColorMode } from '@chakra-ui/react'
import { getModalDevices } from 'api/devices'
import { dropdownStyleConfig } from 'globals/dropdownStyleConfig'
import { useNavigate } from 'react-router-dom'
import AsyncSelect from 'react-select/async'

export const GlobalSearchModal = ({ isOpen, onClose }) => {
	let abortController = null
	const { colorMode } = useColorMode()
	const navigate = useNavigate()

	const getAsyncDevices = async (query) => {
		if (abortController) {
			abortController.abort()
		}

		abortController = new AbortController()
		const response = await getModalDevices(query, abortController.signal)
		return response.data.map((device) => ({
			label: `${device.deviceName} - ${device.serialNumber}`,
			value: device.serialNumber,
			id: device.solHelloDeviceId,
		}))
	}

	const dropdownStyle = colorMode === 'dark' && dropdownStyleConfig

	return (
		<Modal isOpen={isOpen} onClose={onClose} size='3xl'>
			<ModalOverlay backdropFilter='auto' backdropBlur='2px' />
			<ModalContent>
				<ModalBody m={0} p={0}>
					<AsyncSelect
						isClearable
						closeMenuOnSelect={false}
						placeholder='Search devices'
						styles={dropdownStyle}
						menuIsOpen
						name='devices-modal'
						loadOptions={getAsyncDevices}
						onChange={(e, actionMeta) => {
							if (actionMeta.action === 'select-option') {
								onClose()
								return navigate(`devices/${e.value}/${e.id}`)
							}
						}}
					/>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
