import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { AuthProvider } from 'oidc-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Provider } from 'jotai'
import './index.css'
import { HelmetProvider } from 'react-helmet-async'
import theme from 'globals/globalTheme'
import { authConfig } from 'constants/authConstants'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: false,
		},
	},
})

ReactDOM.createRoot(document.getElementById('root')).render(
	<StrictMode>
		<ChakraProvider resetCSS theme={theme}>
			<AuthProvider {...authConfig}>
				<QueryClientProvider client={queryClient}>
					<BrowserRouter>
						<Provider>
							<HelmetProvider>
								<App />
								<ReactQueryDevtools initialIsOpen={false} />
							</HelmetProvider>
						</Provider>
					</BrowserRouter>
				</QueryClientProvider>
			</AuthProvider>
		</ChakraProvider>
	</StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
