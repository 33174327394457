export const dropdownStyleConfig = {
	control: (baseStyles) => ({
		...baseStyles,
		backgroundColor: 'gray.800',
		borderColor: 'inherit',
		':hover': {
			borderColor: 'rgba(255, 255, 255, 0.24)',
		},
		rderColor: '#63b3ed',
	}),
	input: (baseStyles) => ({
		...baseStyles,
		color: 'whiteAlpha.900',
	}),
	placeholder: (baseStyles) => ({
		...baseStyles,
		color: 'whiteAlpha.900',
	}),
	singleValue: (baseStyles) => ({
		...baseStyles,
		color: 'whiteAlpha.900',
	}),
	menuList: (baseStyles) => ({
		...baseStyles,
		backgroundColor: 'rgba(50, 49, 48, 1)',
		border: '1px solid rgba(255, 255, 255, 0.2)',
		borderRadius: '9px',
	}),
	menu: (baseStyles) => ({
		...baseStyles,
		borderRadius: '11px',
	}),
	option: (baseStyles, state) => ({
		...baseStyles,
		backgroundColor: state.isSelected ? '#ffffff0a' : 'inherit',
		':hover': {
			backgroundColor: state.isSelected ? '#ffffff0a' : '#ffffff0a',
		},
	}),
	multiValue: (baseStyles) => ({
		...baseStyles,
		backgroundColor: 'rgb(204, 204, 204)',
		borderRadius: '5px',
		color: 'black',
	}),
}
