import { modalAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(modalAnatomy.keys)

const baseStyle = definePartsStyle({
	dialog: {
		_dark: {
			backgroundColor: '#222222',
		},
	},
	overlay: {
		_dark: {
			backdropFilter: 'auto',
			backdropBlur: '6px',
		},
	},
})

export const modalTheme = defineMultiStyleConfig({ baseStyle })
