import {
	Box,
	Flex,
	Input,
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Select,
	HStack,
	Button,
	CardBody,
	CardFooter,
} from '@chakra-ui/react'
import { flexRender } from '@tanstack/react-table'

const dropdownSize = [10, 20, 30, 40, 50]

const fetching = 'fetching'

export const MainLayoutTable = ({ table, hidePagination, fetchStatus = 'idle' }) => {
	return (
		<>
			<CardBody py='0'>
				<TableContainer maxHeight='60vh' overflowY='auto'>
					<Table>
						<Thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<Tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<Th key={header.id} colSpan={header.colSpan}>
											{header.isPlaceholder ? null : (
												<Flex
													alignItems='center'
													{...{
														style: { cursor: header.column.getCanSort() ? 'pointer' : 'initial' },
														onClick: header.column.getToggleSortingHandler(),
													}}>
													{flexRender(header.column.columnDef.header, header.getContext())}
													{{
														asc: (
															<Box className='material-symbols-outlined' fontSize={16}>
																expand_less
															</Box>
														),
														desc: (
															<Box className='material-symbols-outlined' fontSize={16}>
																expand_more
															</Box>
														),
													}[header.column.getIsSorted()] ?? null}
												</Flex>
											)}
										</Th>
									))}
								</Tr>
							))}
						</Thead>
						<Tbody opacity={fetchStatus === fetching ? 0.4 : 1}>
							{table.getRowModel().rows.map((row) => (
								<Tr key={row.id}>
									{row.getVisibleCells().map((cell) => (
										<Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
									))}
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</CardBody>
			{!hidePagination && (
				<CardFooter>
					<HStack spacing={2} ml='auto' flexWrap='wrap'>
						<Flex whiteSpace='nowrap' alignItems='center'>
							Go to page:
							<Input
								ml={2}
								width='60px'
								type='number'
								defaultValue={table.getState().pagination.pageIndex + 1}
								onChange={(e) => {
									const page = e.target.value ? Number(e.target.value) - 1 : 0
									table.setPageIndex(page)
								}}
							/>
						</Flex>
						<Select
							width='120px'
							value={table.getState().pagination.pageSize}
							onChange={(e) => {
								table.setPageSize(Number(e.target.value))
							}}>
							{dropdownSize.map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									Show {pageSize}
								</option>
							))}
						</Select>
						<Button
							onClick={() => table.setPageIndex(0)}
							isDisabled={!table.getCanPreviousPage()}
							variant='ghost'
							colorScheme='messenger'>
							<span className='material-symbols-outlined'>first_page</span>
						</Button>
						<Button
							onClick={() => table.previousPage()}
							isDisabled={!table.getCanPreviousPage()}
							variant='ghost'
							colorScheme='messenger'>
							<span className='material-symbols-outlined'>chevron_left</span>
						</Button>
						<Flex whiteSpace='nowrap' alignItems='center'>
							Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
						</Flex>
						<Button onClick={() => table.nextPage()} isDisabled={!table.getCanNextPage()} variant='ghost' colorScheme='messenger'>
							<span className='material-symbols-outlined'>chevron_right</span>
						</Button>
						<Button
							onClick={() => table.setPageIndex(table.getPageCount() - 1)}
							isDisabled={!table.getCanNextPage()}
							variant='ghost'
							colorScheme='messenger'>
							<span className='material-symbols-outlined'>last_page</span>
						</Button>
					</HStack>
				</CardFooter>
			)}
		</>
	)
}
