import { BASE_URL } from 'constants/authConstants'
import { getAccessToken } from 'helpers/tokenStore'

const fetchData = async (method, url, options = {}) => {
	try {
		const token = getAccessToken()
		const response = await fetch(`${BASE_URL}/${url}`, {
			method,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			...options,
		})
		if (!response.ok) {
			console.error(`Request failed with status code: ${response.status}`)
			throw new Error(`Request failed`, {
				cause: {
					response,
					errorResponse: await response.json(),
				},
			})
		}
		const resolvedResponse = await response.json()
		if (resolvedResponse.hasSucceeded === false) {
			console.error(resolvedResponse.errorResponse.message)
			throw new Error('Request failed', {
				cause: resolvedResponse,
			})
		} else {
			return resolvedResponse
		}
	} catch (error) {
		throw error
	}
}

export default fetchData
