import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Flex, SimpleGrid, Spinner } from '@chakra-ui/react'
import { ErrorBoundary } from 'react-error-boundary'
import { BreadcrumbComponent, ErrorPage } from 'components'
import { QueryErrorResetBoundary } from '@tanstack/react-query'
import Sidebar from 'layout/Sidebar'

const Layout = () => {
	return (
		<Flex height='100%'>
			<Sidebar />
			<Box flex={1} padding={7} h='100vh' overflow='auto'>
				<BreadcrumbComponent />
				<QueryErrorResetBoundary>
					{({ reset }) => (
						<ErrorBoundary FallbackComponent={ErrorPage} onReset={reset}>
							<Suspense
								fallback={
									<SimpleGrid placeItems='center' height='90%'>
										<Spinner thickness='4px' speed='0.4s' color='blue.400' size='xl' />
									</SimpleGrid>
								}>
								<Outlet />
							</Suspense>
						</ErrorBoundary>
					)}
				</QueryErrorResetBoundary>
			</Box>
		</Flex>
	)
}

export default Layout
