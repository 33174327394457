const userRoles = ['hac.admin', 'directlink', 'endusers']

const getUserRole = (roles) => {
	if (typeof roles === 'string') {
		return userRoles.includes(roles) ? roles : 'User'
	}

	if (Array.isArray(roles) && roles.length) {
		const matchingRole = userRoles.find((role) => roles.includes(role))
		return matchingRole || 'User'
	}

	return 'User'
}

export default getUserRole
