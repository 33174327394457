import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { cardTheme, tableTheme, skeletonTheme, modalTheme, drawerTheme, statTheme } from 'components/chakraComponents'

const config = {
	initialColorMode: 'dark',
}

const styles = {
	global: (props) => ({
		body: {
			color: mode('gray.800', 'whiteAlpha.900')(props),
			backgroundColor: mode('#f9f9f9', '#242424')(props),
		},
	}),
}

const colors = {
	midGray: '#323130',
}

const components = {
	Card: cardTheme,
	Table: tableTheme,
	Skeleton: skeletonTheme,
	Modal: modalTheme,
	Drawer: drawerTheme,
	Stat: statTheme,
}

const theme = extendTheme({ config, styles, components, colors })

export default theme
