import { Spinner } from '@chakra-ui/react'
import { useAuth } from 'oidc-react'
import AppRoutes from './routes'
import { setAccessToken } from 'helpers'

const signinOidcPath = '/signin-oidc'

const locationPath = window.location.pathname

const App = () => {
	const { userData } = useAuth()

	if (!userData && locationPath !== signinOidcPath) {
		localStorage.setItem('redirectPath', locationPath)
	}

	if (userData) {
		setAccessToken(userData.access_token)
		return <AppRoutes />
	}

	return <Spinner />
}

export default App
