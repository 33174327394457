import { Box, Heading, Text, Button } from '@chakra-ui/react'

export const ErrorPage = ({ error, resetErrorBoundary }) => (
	<Box textAlign='center' py={10} px={6}>
		<Heading display='inline-block' as='h2' size='2xl' bgGradient='linear(to-r, teal.400, teal.600)' backgroundClip='text'>
			{error.name}
		</Heading>
		<Text fontSize='18px' mt={3} mb={2}>
			Something went wrong
		</Text>
		<Text color='gray.500' mb={6}>
			{error.message}
		</Text>
		<Button onClick={() => window.location.replace(window.location.origin)} colorScheme='teal' variant='solid' mr='4'>
			Go to Home
		</Button>
		<Button onClick={resetErrorBoundary} colorScheme='yellow' variant='solid'>
			Try again
		</Button>
	</Box>
)
